@charset "UTF-8";
/*
COMMON
***********************************/
/* header・nav */
@media screen and (max-width: 727px) { .head_sp .nav_sp { box-sizing: border-box; max-height: calc(100vh - 52.58px); overflow: auto; padding: 0 0 50px; } }

/*
PARTS
***********************************/
/* block・title */
.subpage_title .icon_pen { background: url(../img/common/icon_pen.png) no-repeat left top 3px/auto 36px; padding: 8px 0 4px 40px; }

/*
PAGE
***********************************/
/* rireki・shokumu・mensetsu */
.rireki_link .listbox { display: flex; flex-wrap: wrap; }

@media screen and (max-width: 727px) { .rireki_link .listbox { display: block; } }

.rireki_link .listbox li { width: 49%; }

@media screen and (max-width: 727px) { .rireki_link .listbox li { width: 98%; } }

.rireki_link .listbox li:nth-child(2n+2) { margin-right: 0; }

.rireki_link .listbox li .photo img { display: block; margin: 0 auto; }

/*
転職コラム
***********************************/
/* 一覧 */
.column_list { margin: 0 auto 80px; max-width: 595px; }

.column_list_article { background: #fff3eb; margin: 0 0 6px; }

.column_list_link { align-items: center; display: flex; }

.column_list_thumbnail { background: #e5e5e5; flex: none; height: 130px; width: 130px; }

@media screen and (max-width: 727px) { .column_list_thumbnail { height: 152px; width: 152px; } }

.column_list_img { display: block; }

.column_list_content { overflow: hidden; min-height: 68px; padding: 21px 19px 16px; }

.column_list_title { color: #c12748; font-size: 16px; font-weight: bold; line-height: 22px; height: 44px; margin: 0 0 5px; overflow: hidden; text-align: justify; }

@media screen and (max-width: 727px) { .column_list_title { height: 66px; } }

.column_list_text { font-size: 13px; line-height: 22px; height: 44px; overflow: hidden; text-align: justify; }

.column_list_pagination { align-items: center; display: flex; flex-wrap: wrap; justify-content: center; margin: 40px 0 0; }

.column_list_pagination li { margin: 0 4px; }

.column_list_pagination a { align-items: center; background: #b4b4b4; color: #fff; display: flex; font-size: 13px; height: 30px; line-height: 1; padding: 0 12px; }

.column_list_pagination a:hover, .column_list_pagination a.current { background: #000; }

.column_list_pagination a.current { pointer-events: none; }

/* 詳細 */
.column_detail { margin: 0 auto 80px; }

.column_detail_button { display: flex; justify-content: flex-end; margin: 20px 0; }

@media screen and (max-width: 727px) { .column_detail_button { margin: 0 0 20px; } }

.column_detail_button .fb-share-button, .column_detail_button .fb-like { margin: 0 0 0 5px; }

.column_detail_title { background: url(../img/common/icon_pen.png) no-repeat left 16px top/auto 36px; border-bottom: 3px solid #d82a39; color: #d82a39; font-size: 22px; line-height: 30px; margin: 0 0 40px; padding: 3px 0 10px 60px; }

.column_detail_text { font-size: 15px; line-height: 25px; margin: 0 0 40px; }

@media screen and (max-width: 727px) { .column_detail_text { font-size: inherit; line-height: inherit; margin: 0 15px 40px; } }

.column_detail_img { display: block; margin: 0 auto; max-width: 480px; width: 100%; }

.column_detail_content h2 { background: #fff3eb; color: #d82a39; font-size: 18px; font-weight: bold; line-height: 25px; margin: 40px 0 10px; padding: 15px 30px; position: relative; }

@media screen and (max-width: 727px) { .column_detail_content h2 { margin: 40px 0 10px; padding: 15px; } }

.column_detail_content h2::before { background: #f1a297; content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 5px; }

.column_detail_content h3, .column_detail_content h4, .column_detail_content h5, .column_detail_content h6 { font-size: 16px; font-weight: bold; line-height: 23px; margin: 20px 30px 10px; }

@media screen and (max-width: 727px) { .column_detail_content h3, .column_detail_content h4, .column_detail_content h5, .column_detail_content h6 { margin: 20px 15px 10px; } }

.column_detail_content h3::before, .column_detail_content h4::before, .column_detail_content h5::before, .column_detail_content h6::before { content: "\025a0"; }

.column_detail_content p { font-size: 13px; line-height: 24px; margin: 10px 30px; }

@media screen and (max-width: 727px) { .column_detail_content p { margin: 10px 15px; } }

.column_detail_pagenation { align-items: center; display: flex; margin: 40px 0 0; }

.column_detail_pagenation li { flex: none; width: 33.3333%; }

.column_detail_pagenation li:nth-child(1) { margin: 0 0 0 auto; }

.column_detail_pagenation li:nth-child(2) { margin: auto 0 0 0; }

.column_detail_pagenation li.next, .column_detail_pagenation li.previous { margin: 0; }

.column_detail_pagenation a { color: #000; display: flex; font-size: 13px; flex-direction: column; height: 102px; justify-content: center; line-height: 25px; padding: 0 30px; text-align: center; text-decoration: underline; }

@media screen and (max-width: 727px) { .column_detail_pagenation a { padding: 0 15px; } }

.column_detail_pagenation a:hover { text-decoration: none; }

.column_detail_pagenation li.next a, .column_detail_pagenation li.previous a { background: #efefef; }

.column_detail_pagenation li.next a { text-align: left; }

.column_detail_pagenation li.previous a { text-align: right; }

.column_detail_pagenation span { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.inner.clearfix { overflow: visible; }

.column_sidebar { margin: 0 0 40px; }

.column_sidebar img { display: block; width: 100%; }

@media screen and (max-width: 727px) { .column_sidebar_title { position: relative; } }

@media screen and (max-width: 727px) { .column_sidebar_title::before { background: #d82a39; bottom: 0; content: ""; height: 53px; left: 0; position: absolute; width: 100%; } }

@media screen and (max-width: 727px) { .column_sidebar_title img { margin: 0 auto; max-width: 270px; position: relative; z-index: 10; } }

.column_sidebar_content { background: #ececec; padding: 24px 0 30px; }

.column_sidebar_list { padding: 0 30px 0 16px; }

.column_sidebar_list li { display: flex; font-size: 14px; font-weight: bold; line-height: 24px; margin: 0 0 24px; text-align: justify; }

.column_sidebar_list li::before { content: "・"; flex: none; }

.column_sidebar_list a { color: #000; text-decoration: underline; }

.column_sidebar_list a:hover { text-decoration: none; }

.column_sidebar_button { display: block; margin: 0 auto; width: 162px; }
