/*
COMMON
***********************************/

/* header・nav */
.head_sp .nav_sp {
    @media screen and (max-width: 727px) {
        box-sizing: border-box;
        max-height: calc(100vh - 52.58px);
        overflow: auto;
        padding: 0 0 50px;
    }
}

/*
PARTS
***********************************/

/* block・title */
.subpage_title .icon_pen {
    background: url(../img/common/icon_pen.png) no-repeat left top 3px / auto 36px;
    padding: 8px 0 4px 40px;
}

/*
PAGE
***********************************/

/* rireki・shokumu・mensetsu */
.rireki_link .listbox {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 727px) {
        display: block;
    }
}

.rireki_link .listbox li {
    width: 49%;
    @media screen and (max-width: 727px) {
        width: 98%;
    }
}

.rireki_link .listbox li:nth-child(2n+2) {
    margin-right: 0;
}

.rireki_link .listbox li .photo img {
    display: block;
    margin: 0 auto;
}

/*
転職コラム
***********************************/

/* 一覧 */
.column_list {
    margin: 0 auto 80px;
    max-width: 595px;
    &_article {
        background: #fff3eb;
        margin: 0 0 6px;
    }
    &_link {
        align-items: center;
        display: flex;
    }
    &_thumbnail {
        background: #e5e5e5;
        flex: none;
        height: 130px;
        width: 130px;
        @media screen and (max-width: 727px) {
            height: 152px;
            width: 152px;
        }
    }
    &_img {
        display: block;
    }
    &_content {
        overflow: hidden;
        min-height: 68px;
        padding: 21px 19px 16px;
    }
    &_title {
        color: #c12748;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        height: 44px;
        margin: 0 0 5px;
        overflow: hidden;
        text-align: justify;
        @media screen and (max-width: 727px) {
            height: 66px;
        }
    }
    &_text {
        font-size: 13px;
        line-height: 22px;
        height: 44px;
        overflow: hidden;
        text-align: justify;
    }
    &_pagination {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 40px 0 0;
        li {
            margin: 0 4px;
        }
        a {
            align-items: center;
            background: #b4b4b4;
            color: #fff;
            display: flex;
            font-size: 13px;
            height: 30px;
            line-height: 1;
            padding: 0 12px;
        }
        a:hover, a.current {
            background: #000;
        }
        a.current {
            pointer-events: none;
        }
    }
}

/* 詳細 */
.column_detail {
    margin: 0 auto 80px;
    &_button {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
        @media screen and (max-width: 727px) {
            margin: 0 0 20px;
        }
        .fb-share-button, .fb-like {
            margin: 0 0 0 5px;
        }
    }
    &_title {
        background: url(../img/common/icon_pen.png) no-repeat left 16px top / auto 36px;
        border-bottom: 3px solid #d82a39;
        color: #d82a39;
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 40px;
        padding: 3px 0 10px 60px;
    }
    &_text {
        font-size: 15px;
        line-height: 25px;
        margin: 0 0 40px;
        @media screen and (max-width: 727px) {
            font-size: inherit;
            line-height: inherit;
            margin: 0 15px 40px;
        }
    }
    &_img {
        display: block;
        margin: 0 auto;
        max-width: 480px;
        width: 100%;
    }
    &_content {
        h2 {
            background: #fff3eb;
            color: #d82a39;
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            margin: 40px 0 10px;
            padding: 15px 30px;
            position: relative;
            @media screen and (max-width: 727px) {
                margin: 40px 0 10px;
                padding: 15px;
            }
        }
        h2::before {
            background: #f1a297;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 5px;
        }
        h3, h4, h5, h6 {
            font-size: 16px;
            font-weight: bold;
            line-height: 23px;
            margin: 20px 30px 10px;
            @media screen and (max-width: 727px) {
                margin: 20px 15px 10px;
            }
            &::before {
                content: "\025a0";
            }
        }
        p {
            font-size: 13px;
            line-height: 24px;
            margin: 10px 30px;
            @media screen and (max-width: 727px) {
                margin: 10px 15px;
            }
        }
    }
    &_pagenation {
        align-items: center;
        display: flex;
        margin: 40px 0 0;
        li {
            flex: none;
            width: 33.3333%;
        }
        li:nth-child(1) {
            margin: 0 0 0 auto;
        }
        li:nth-child(2) {
            margin: auto 0 0 0;
        }
        li.next, li.previous {
            margin: 0;
        }
        a {
            color: #000;
            display: flex;
            font-size: 13px;
            flex-direction: column;
            height: 102px;
            justify-content: center;
            line-height: 25px;
            padding: 0 30px;
            text-align: center;
            text-decoration: underline;
            @media screen and (max-width: 727px) {
                padding: 0 15px;
            }
        }
        a:hover {
            text-decoration: none;
        }
        li.next a, li.previous a {
            background: #efefef;
        }
        li.next a {
            text-align: left;
        }
        li.previous a {
            text-align: right;
        }
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.inner.clearfix {
    overflow: visible;
}

.column_sidebar {
    margin: 0 0 40px;
    img {
        display: block;
        width: 100%;
    }
    &_title {
        @media screen and (max-width: 727px) {
            position: relative;
        }
        &::before {
            @media screen and (max-width: 727px) {
                background: #d82a39;
                bottom: 0;
                content: "";
                height: 53px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }
        img {
            @media screen and (max-width: 727px) {
                margin: 0 auto;
                max-width: 270px;
                position: relative;
                z-index: 10;
            }
        }
    }
    &_content {
        background: #ececec;
        padding: 24px 0 30px;
    }
    &_list {
        padding: 0 30px 0 16px;
        li {
            display: flex;
            font-size: 14px;
            font-weight: bold;
            line-height: 24px;
            margin: 0 0 24px;
            text-align: justify;
        }
        li::before {
            content: "・";
            flex: none;
        }
        a {
            color: #000;
            text-decoration: underline;
        }
        a:hover {
            text-decoration: none;
        }
    }
    &_button {
        display: block;
        margin: 0 auto;
        width: 162px;
    }
}
